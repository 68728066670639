export const getUrlParameter = (name) => {
  const nameValue = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${nameValue}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
window.getUrlParameter = getUrlParameter;

export const setUrlParameterAndReload = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  window.location.search = searchParams.toString();
};

export const clearUrlParameterAndReload = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  window.location.search = searchParams.toString();
};

export const clearMultiUrlParameterAndReload = (paramName, valueToClear) => {
  const filterName = `${paramName}[]`;
  const filteredParams = [];
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.forEach((value, key) => {
    if (key !== filterName || value !== valueToClear) {
      filteredParams.push([key, value]);
    }
  });
  const newParams = new URLSearchParams(filteredParams);
  window.location.search = newParams.toString();
};

export const removeQuerystring = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.search = "";
  return a.href;
};
